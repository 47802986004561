import { View, Text } from "@react-pdf/renderer";

const TitlePDF = ({ text, subtitle }) => {
  return (
    <View style={{ display: "block", margin: "1em 0" }}>
      <Text
        style={{
          fontFamily: "Helvetica-Bold",
          display: "block",
        }}
      >
        {text}
        {!subtitle && <Text>{"\n"}</Text>}
        {"\n"}
      </Text>
      {subtitle ? (
        <Text
          style={{
            fontFamily: "Helvetica-Oblique",
          }}
        >
          {subtitle}
          {"\n"}
          {"\n"}
        </Text>
      ) : (
        ""
      )}
    </View>
  );
};

export default TitlePDF;
