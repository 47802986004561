import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SubNav from "../../Elements/SubNav";
import "./education.css";
import Fade from "react-reveal/Fade";

const EducationPage = () => {
  return (
    <span id="education" style={{ overflowX: "hidden" }}>
      <SubNav title="Private Front-End Development Course" />
      <Container>
        <main style={{ padding: "2rem 0" }}>
          <Row md>
            <Col md className="textColumnPic">
              <Fade>
                <h1 style={{ marginBottom: "2rem" }}>
                  Personalized coding classes
                </h1>

                <p>
                  Online courses tend to lack the mentorship necessary to lead
                  an absolute coding beginner into a person who is able to
                  compete and perform in the job market. Although coding
                  bootcamps claim to do the same, their price and
                  student-to-instructor ratio are outrageously high.{" "}
                </p>
              </Fade>
            </Col>
            <Fade left>
              <Col md>
                <img
                  className="teachingPicture"
                  alt="learning"
                  // src="https://drive.google.com/uc?export=view&id=16o9klaHSVRLNkoY6SqNWBzQbJyCY3pHG"
                  src="http://www.alfredorafael.com/wp-content/uploads/2022/12/Ying-here.jpg"
                />
              </Col>
            </Fade>
          </Row>
          <div style={{ height: "1rem" }}></div>
          <hr style={{ border: ".5px solid #D3D3D3", maxWidth: "40%" }} />
          <div style={{ height: "1rem" }}></div>

          <h1 style={{ marginBottom: "2rem" }}>Course Description</h1>

          <p>
            {" "}
            Test. Here goes information about the curriculum to be followed.
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sed
            eligendi illo explicabo animi numquam sapiente dolor, harum eaque
            dicta doloremque ullam quae nam ab expedita distinctio commodi
            consequuntur itaque. Itaque. Itaque.{" "}
          </p>

          <div style={{ height: "1rem" }}></div>
          <hr style={{ border: ".5px solid #D3D3D3", maxWidth: "40%" }} />
          <div style={{ height: "1rem" }}></div>

          <h1 style={{ marginBottom: "2rem" }}>Schedule</h1>

          <p>
            {" "}
            STARTING JANUARY 4, 2023 <br />
            8 Week Course (Jan 4 to Feb 25) <br />
            48 hours each class <br />
            Total of 36 hours <br /> Wed: 6 to 9pm CT & Sat: 10am to 1pm CT{" "}
            <br /> Homework Tasks <br /> Maximum 4 students <br />
            <br />
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sed
            eligendi illo explicabo animi numquam sapiente dolor, harum eaque
            dicta doloremque ullam quae nam ab expedita distinctio commodi
            consequuntur itaque. Quod. Apr 3{" "}
          </p>

          {/* <Row md>
            <Col md>
            <img
              className="teachingPicture"
              alt="learning"
              src="https://drive.google.com/uc?export=view&id=16o9klaHSVRLNkoY6SqNWBzQbJyCY3pHG"

            />
            </Col>
            <Col md className="textColumnPic">
            <div>
              <h1>How personalized coding classes make a difference?</h1>
            
            Online courses tend to lack the mentorship necessary to lead an absolute coding beginner into a person who is able to compete and perform in the job market. Although coding bootcamps claim to do the same, both their price tag and student-to-instructor ratio are incredibly higher.     
          </div>
          </Col>
         
          </Row> */}
        </main>
      </Container>
    </span>
  );
};

export default EducationPage;
