import React from "react";
import "./heroBanner.css";
import TypeWriter from "../2TypeWriter";

const HeroBanner = () => {
  // const [isLoaded, setIsLoaded] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoaded(true);
  //   }, 6000);
  // }, []);

  return (
    <div>
      <span className="dynamicIntro">
        <TypeWriter />
      </span>
      <div
        style={{ background: "black" }}
        className="fullscreen-video-wrap"
        dangerouslySetInnerHTML={{
          __html: `

        <video
          style="opacity: .7; width: 100%; margin-bottom: -.5%"
          playsinline 
          loop 
          autoPlay 
          muted 
          autobuffer 
          poster="http://www.alfredorafael.com/wp-content/uploads/2023/12/working-and-studying-e1633667573642.jpg"
        >

        <source 
          src="http://www.alfredorafael.com/wp-content/uploads/2023/12/hero-clip.mp4" type="video/mp4" />
        
        <source 
          src="http://www.alfredorafael.com/wp-content/uploads/2023/12/hero-clip.mp4" type="video/ogg" />
        Your browser does not support the video tag. I suggest you upgrade your browser.

        </video>     
`,
        }}
      ></div>
      <hr
        style={{
          border: ".5px solid #D3D3D3",
          maxWidth: "70%",
          marginTop: "1em",
        }}
      />
    </div>
  );
};

export default HeroBanner;
