import React from "react";
import { Container, Navbar } from "react-bootstrap";
import "./clients.css";
import Fade from "react-reveal/Fade";
import Division from "../../../Elements/Division";

const MasalaTwist = () => {
  return (
    <span id="clients" style={{ overflowX: "hidden" }}>
      <Navbar style={{ background: "#7573bf" }}>
        <Container>
          <Navbar.Brand style={{ color: "white" }}>
            <img
              alt="logo"
              src="https://cateringwebsitetest.com/wp-content/uploads/2023/05/MasalaTwistLogo.png"
              style={{ maxWidth: "13%", margin: "0 5% 0 3%" }}
            />
            <strong>Catering Website</strong>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container>
        <main style={{ padding: "2rem 0" }}>
          <Fade>
            <h3 style={{ marginBottom: "2rem" }}>Steps & Action Plan</h3>
            <div style={{ display: "inline" }}></div>
            <p>
              <strong>Common Access: </strong>
              To begin, we'll transfer{" "}
              <a
                href="https://cateringwebsitetest.com/"
                target="_blank"
                rel="noreferrer"
              >
                <strong>our template website </strong>
              </a>
              to your GoDaddy account. Please share your GoDaddy username and
              password for the process. Once you have ownership, both of us can
              upload media (pictures, videos, etc.) for later use. I look
              forward to guiding you through the process of uploading these.
            </p>

            <strong>Deadline setting: </strong>
            <p>
              In our next meeting, let's establish clear dates for achieving our
              goals. Setting deadlines and aligning them with our calendars will
              ensure timely completion of the catering website without that rush
              feeling at the end.
            </p>
            <p>
              <strong>Content & Design: </strong>
              Our next step involves two parts. First, we'll determine the pages
              your website should have, keeping in mind what your clients need
              to see. Then, we'll define the content (text, images, etc.) for
              each page. I'll handle the web design and incorporate any
              necessary features.
            </p>

            <p>
              <strong>Refine & Deliver: </strong>
              Once the webpage is completed, we'll move on to deploying and
              enabling all required SEO tools for public access. Additionally, I
              look forward to providing training (to you and/or staff) on
              updating the website for different purposes, such as price changes
              and special offers.
            </p>
          </Fade>
          <p>
            Looking forward to meeting you soon and please free to ask if you
            need further clarification or have any other questions.
          </p>
          Till soon!
          <br />
          <strong>Alfredo R. Pabón</strong>
          <Division />
        </main>
      </Container>
    </span>
  );
};

export default MasalaTwist;
