import React from "react";

const TheLink = ({ url, text }) => {
  return (
    <a href={`https://${url}`} target="_blank" rel="noopener noreferrer">
      <strong> {text} </strong>
    </a>
  );
};

export default TheLink;
