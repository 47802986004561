import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { StyledFooter } from "./StyledFooter";
import "./footer.css";

const AppFooter = () => {
  return (
    <React.Fragment>
      <StyledFooter id="footer">
        <Container>
          <Row>
            <Col>
              <span>
                {" "}
                Copyright Ⓒ Alfredo R. Pabon, {new Date().getFullYear()}{" "}
              </span>
            </Col>

            <Col>
              <img
                src="http://www.alfredorafael.com/wp-content/uploads/2019/02/whileLogoName-e1551079673184.png"
                alt="Alfredo Rafael Logo"
              />
            </Col>
          </Row>
        </Container>
      </StyledFooter>
    </React.Fragment>
  );
};

export default AppFooter;
