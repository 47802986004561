import React from "react";

const Experience = ({ position, date, company, duties, subduties }) => {
  return (
    <React.Fragment>
      <div>
        <h2>
          <strong>{!position ? "Hardcoded Position" : position}</strong>
          <span>{!date ? "HardCoded Date" : date}</span>
        </h2>
        <span
          className="company-name"
          style={{ fontStyle: "italic", fontSize: "100%" }}
        >
          {!company ? "HardCoded Company" : company}
        </span>
        <br />
        <br />
        <ul>{!duties ? <li>Hardcoded duty</li> : duties}</ul>
        {!subduties ? "" : subduties}
        <hr
          style={{
            height: "1px",
            border: "none",
            backgroundColor: "rgba(105, 186, 213, 0.3)",
            marginTop: "5%",
            width: "70%",
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default Experience;
