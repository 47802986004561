import React from "react";
import { Container, Col, Row, Popover, OverlayTrigger } from "react-bootstrap";
import TheCard from "../Elements/TheCard";
import TheModal from "../Elements/TheModal";
import TextWithThumbnail from "../Elements/TextWithThumbnail";
import TheAccordion from "../Elements/TheAccordion";
import TheLink from "../Elements/TheLink";
import JuxtaposedResponse from "../Elements/JuxtaposedResponse";

const PageNotFound = () => {
  return (
    <div
      style={{
        backgroundImage:
          "linear-gradient(to bottom, rgba(4, 1, 130, 0.1), white)",
        minHeight: "80vh",
      }}
    >
      <Container>
        <br />
        <h1> 404 Page </h1>
        <br />
        Gosh... What are you doing here!?{" "}
        <strong>This page does not exist</strong>. However, if you are seeing
        this page, there is a list of things that may have happened/are
        happening:
        <br />
        <br />
        <ul>
          <li>
            You are trying to access a page that I deleted some time ago. Sorry!
            Reach out to me if that is the case.
          </li>
          <br />
          <li>
            There is a bug in the code that is redirecting you here. If so, I am
            sorry: please let me know if a link is broken, ok?
          </li>
          <br />
          <li>
            You are a sneaky bastard (and probably a fellow developer like
            myself 😃 ) who is trying to access pages that are not available to
            the public . If so, hacking wont work because I keep private
            messages for clients and friends hidden but... good try, sir/maam...
            good try!
          </li>
        </ul>
        <br />
        Whatever the case, may Yawhe, the God of the Bible, bless you and keep
        you safe... Amén.
      </Container>
    </div>
  );
};
export default PageNotFound;
