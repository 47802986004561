import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const TheModal = ({ triggerElement, headerText, children }) => {
  const [showModalState, setShowModalState] = useState(false);
  const hideModal = () => setShowModalState(false);
  const showModal = () => setShowModalState(true);

  return (
    <>
      <span className="modal-word" onClick={showModal}>
        {" "}
        {triggerElement}{" "}
      </span>
      <Modal show={showModalState} onHide={hideModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{headerText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
};

export default TheModal;
