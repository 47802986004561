import React from "react";
import { Container, Col, Row, Popover, OverlayTrigger } from "react-bootstrap";
import TheCard from "../Elements/TheCard";
import TheModal from "../Elements/TheModal";
import TextWithThumbnail from "../Elements/TextWithThumbnail";
import TheAccordion from "../Elements/TheAccordion";
import TheLink from "../Elements/TheLink";
import JuxtaposedResponse from "../Elements/JuxtaposedResponse";

const KichenSink = () => {
  return (
    <div
      style={{
        backgroundImage:
          "linear-gradient(to bottom, rgba(4, 1, 130, 0.1), white)",
      }}
    >
      <Container>
        <br />
        <h1> Kitchen Sink </h1>
        <br />
        <TextWithThumbnail
          imgSrc={"http://placedog.net/200/200"}
          alt={"alt text"}
          right
          imgText={"Some text describing the pic"}
        >
          Starts here <TheLink url="www.google.com" text={"Lorem"} />
          ipsum dolor sit amet consectetur adipisicing elit. Fuga deleniti quos
          excepturi quo consectetur totam consequuntur, odio voluptatem vel
          aperiam atque ducimus sit maxime ea. Mollitia cum illum asperiores
          molestias. Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Fuga deleniti quos excepturi quo consectetur totam consequuntur, odio
          voluptatem vel aperiam atque ducimus sit maxime ea. Mollitia cum illum
          asperiores molestias. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Fuga deleniti quos excepturi quo consectetur totam
          consequuntur, odio voluptatem vel aperiam atque ducimus sit maxime ea.
          Mollitia cum illum asperiores molestias. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Fuga deleniti quos excepturi quo consectetur totam
          consequuntur, odio voluptatem vel aperiam atque ducimus sit maxime ea.
          Mollitia cum illum asperiores molestias. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Fuga deleniti quos excepturi quo
          consectetur totam consequuntur, odio voluptatem vel aperiam atque
          ducimus sit maxime ea. Mollitia cum illum asperiores molestias. Lorem
          ipsum dolor sit amet consectetur adipisicing elit. Fuga deleniti quos
          excepturi quo consectetur totam consequuntur, odio voluptatem vel
          aperiam atque ducimus sit maxime ea. Mollitia cum illum asperiores
          molestias. Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Mollitia cum illum asperiores molestias. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Fuga deleniti quos excepturi quo
          consectetur totam consequuntur, odio voluptatem vel aperiam atque
          ducimus sit maxime ea. Mollitia cum illum asperiores molestias. Lorem
          ipsum dolor sit amet consectetur adipisicing elit. Fuga deleniti quos
          excepturi quo consectetur totam
        </TextWithThumbnail>
        <br />
        <hr style={{ border: ".5px solid #D3D3D3", maxWidth: "40%" }} />
        <br />
        {/* Text with Reply ////////////////////////////////////////////////////////// */}
        <JuxtaposedResponse
          titleLeft={"You said..."}
          titleRight={"Response:"}
          statement={
            <>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. In
              dignissimos perferendis at maiores dolore magnam voluptatum ipsam,
              enim sequi omnis nulla, laborum error molestiae similique quod
              odit eligendi iure nisi.
            </>
          }
          response={
            <span>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. In
              dignissimos perferendis at maiores dolore magnam voluptatum ipsam,
              enim sequi omnis nulla, laborum error molestiae similique quod
              odit eligendi iure nisi. Lorem ipsum, dolor sit amet consectetur
              adipisicing elit. In dignissimos perferendis at maiores dolore
              magnam voluptatum ipsam, enim sequi omnis nulla, laborum error
              molestiae similique quod odit eligendi iure nisi. Lorem ipsum,
              dolor sit amet consectetur adipisicing elit. In dignissimos
              perferendis at maiores dolore magnam voluptatum ipsam, enim sequi
              omnis nulla, laborum error molestiae similique quod odit eligendi
              iure nisi.
            </span>
          }
        />
        <JuxtaposedResponse
          statement={
            <>
              In dignissimos perferendis at maiores dolore magnam voluptatum
              ipsam, enim sequi omnis nulla, laborum error molestiae similique
              quod odit eligendi iure nisi."
            </>
          }
          response={
            <>
              In dignissimos perferendis at maiores dolore magnam voluptatum
              ipsam, enim sequi omnis nulla, laborum error molestiae similique
              quod odit eligendi iure nisi.
              <TheLink
                url="www.youtube.com/watch?v=gDfQHWQwJ8Q&t=11s"
                text={"Linked text"}
              />
              In dignissimos perferendis at maiores dolore magnam voluptatum
              ipsam, enim sequi omnis nulla, laborum error molestiae similique
              quod odit eligendi iure nisi.
            </>
          }
        />
        <br />
        {/* ACCORDIONS //////////////////////////////////////////////////////////////////// */}
        <h4>Accordion</h4>
        <TheAccordion cardHeader="Pulse aquí" number="0">
          <div>El primero</div>
        </TheAccordion>
        <TheAccordion cardHeader="Click me" number="1">
          <div>El numero dos</div>
        </TheAccordion>{" "}
        {/* CARDS  //////////////////////////////////////////////////////////////////// */}
        <h4>Cards</h4>
        <br />
        <Row className="cards">
          <Col>
            <TheCard headerText="Card 1">Simple Card 1, no hover</TheCard>
          </Col>

          <Col>
            <TheCard hoverEffect headerText="Card 2">
              Simple Card 2, w/hoverEffect Body
            </TheCard>
          </Col>

          <Col>
            <TheModal
              triggerElement={
                <TheCard
                  headerText="Card 3"
                  hoverEffect
                  style={{ color: "black" }}
                >
                  Simple Card 3, with modal
                </TheCard>
              }
              headerText="Another Modal"
            >
              {/* Content inside the modal */}
              <p>This is the modal.</p>
            </TheModal>
          </Col>
        </Row>
        <br />
        <br />
      </Container>
    </div>
  );
};
export default KichenSink;
