import React from "react";
import { Accordion, Card } from "react-bootstrap";

const TheAccordion = ({ cardHeader, children, number }) => {
  return (
    <Accordion style={{ margin: "1em 0" }}>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey={number}>
          {cardHeader}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={number}>
          <Card.Body>{children}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default TheAccordion;
