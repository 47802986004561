import React from "react";
import { Container, Row, Col, Card, Accordion } from "react-bootstrap";
import SubNav from "../../../Elements/SubNav";
import TheLink from "../../../Elements/TheLink";
import JuxtaposedResponse from "../../../Elements/JuxtaposedResponse";

const Jola = () => {
  return (
    <span id="jola">
      <SubNav
        title="Jola's Reply 1"
        // img="http://www.alfredorafael.com/wp-content/uploads/2024/08/ItsJola-logo1.png"
      />
      <Container style={{ minHeight: "70vh" }}>
        <JuxtaposedResponse
          titleLeft={"You said..."}
          titleRight={"Response:"}
          statement={
            <>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. In
              dignissimos perferendis at maiores dolore magnam voluptatum ipsam,
              enim sequi omnis nulla, laborum error molestiae similique quod
              odit eligendi iure nisi.
            </>
          }
          response={
            <span>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. In
              dignissimos perferendis at maiores dolore magnam voluptatum ipsam,
              enim sequi omnis nulla, laborum error molestiae similique quod
              odit eligendi iure nisi. Lorem ipsum, dolor sit amet consectetur
              adipisicing elit. In dignissimos perferendis at maiores dolore
              magnam voluptatum ipsam, enim sequi omnis nulla, laborum error
              molestiae similique quod odit eligendi iure nisi. Lorem ipsum,
              dolor sit amet consectetur adipisicing elit. In dignissimos
              perferendis at maiores dolore magnam voluptatum ipsam, enim sequi
              omnis nulla, laborum error molestiae similique quod odit eligendi
              iure nisi.
            </span>
          }
        />
        <JuxtaposedResponse
          statement={
            <>
              In dignissimos perferendis at maiores dolore magnam voluptatum
              ipsam, enim sequi omnis nulla, laborum error molestiae similique
              quod odit eligendi iure nisi."
            </>
          }
          response={
            <>
              In dignissimos perferendis at maiores dolore magnam voluptatum
              ipsam, enim sequi omnis nulla, laborum error molestiae similique
              quod odit eligendi iure nisi.
              <TheLink
                url="www.youtube.com/watch?v=gDfQHWQwJ8Q&t=11s"
                text={"Linked text"}
              />
              In dignissimos perferendis at maiores dolore magnam voluptatum
              ipsam, enim sequi omnis nulla, laborum error molestiae similique
              quod odit eligendi iure nisi.
            </>
          }
        />
        <br />
        <br />
      </Container>
      <style jsx>
        {`
          body {
            background-image: linear-gradient(
              to bottom,
              rgba(4, 1, 130, 0.1),
              white
            );
          }
        `}
      </style>
    </span>
  );
};
export default Jola;
