import React, { useState } from "react";
import { Container, Row, Col, Button, Card, Modal } from "react-bootstrap";
import { Fade } from "react-reveal";
import "./projects.css";

const Projects = () => {
  //modals setup:
  const [showBofaState, setShowBofa] = useState(false);
  const handleCloseBofa = () => setShowBofa(false);
  const showBofa = () => setShowBofa(true);

  const [showMITState, setShowMIT] = useState(false);
  const handleCloseMIT = () => setShowMIT(false);
  const showMIT = () => setShowMIT(true);

  const [showRelataState, setShowRelata] = useState(false);
  const handleCloseRelata = () => setShowRelata(false);
  const showRelata = () => setShowRelata(true);

  const [showASAState, setShowASA] = useState(false);
  const handleCloseASA = () => setShowASA(false);
  const showASA = () => setShowASA(true);

  const [showMelodiasState, setShowMelodias] = useState(false);
  const handleCloseMelodias = () => setShowMelodias(false);
  const showMelodias = () => setShowMelodias(true);

  const [showMoviesState, setShowMovies] = useState(false);
  const handleCloseMovies = () => setShowMovies(false);
  const showMovies = () => setShowMovies(true);

  return (
    <React.Fragment>
      <div id="projectsComponent">
        <br />
        <Container id="projects">
          <h2>
            <hr style={{ border: ".5px solid #D3D3D3", maxWidth: "45%" }} />{" "}
            <strong>
              Previous <span>Work</span>
            </strong>
          </h2>
          <Fade>
            <Row className="project-rows">
              <Col>
                <Card className="highlight-on-hover" onClick={showMIT}>
                  <Card.Img
                    variant="top"
                    src="http://www.alfredorafael.com/wp-content/uploads/2024/02/CodeSample1.png"
                  />
                  <Card.Body>
                    <Card.Title>M.I.T.</Card.Title>
                  </Card.Body>
                </Card>

                <Modal size="lg" show={showMITState} onHide={handleCloseMIT}>
                  <Modal.Header className="proj-modal-header" closeButton>
                    <Modal.Title>M.I.T.</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <div className="flexed-modal-body-proj">
                        <img
                          className="proj-modal-pic"
                          src="http://www.alfredorafael.com/wp-content/uploads/2024/02/CodeSample1.png"
                        />
                        <div className="proj-modal-text">
                          At MIT's department of Chemical Engineering I code,
                          redesign, and update webpages within the M.I.T's
                          Chemical Engineering department. Wordpress is a
                          powerful tool to develop enterprise level
                          applications, allowing clients edit their website's
                          content thru a wysiwyg editor. I use custom HTML,
                          vanilla CSS, use Sketch to create/modify logos, and
                          additional Worpdress pluggings to enhance user
                          experience.
                        </div>
                      </div>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <a target="_target" href="https://cheme.mit.edu/">
                      <Button onClick={handleCloseMIT}>
                        {" "}
                        <i class="fas fa-tv"></i> &nbsp;Visit Site
                      </Button>
                    </a>

                    <a href="https://cheme.mit.edu/" target="_blank"></a>
                    <Button
                      style={{
                        background: "white",
                        color: "#040182",
                        border: "1px solid #040182",
                      }}
                      onClick={handleCloseMIT}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Col>

              {/* //////////////////////////////////// */}

              <Col>
                <Card className="highlight-on-hover" onClick={showBofa}>
                  <Card.Img
                    variant="top"
                    src="http://www.alfredorafael.com/wp-content/uploads/2024/02/CodeSample2.png"
                  />
                  <Card.Body>
                    <Card.Title>Merrill Lynch</Card.Title>
                  </Card.Body>
                </Card>

                <Modal size="lg" show={showBofaState} onHide={handleCloseBofa}>
                  <Modal.Header className="proj-modal-header" closeButton>
                    <Modal.Title>Merrill Lynch</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <div className="flexed-modal-body-proj">
                        <img
                          className="proj-modal-pic"
                          src="http://www.alfredorafael.com/wp-content/uploads/2024/02/CodeSample2.png"
                        />
                        <div className="proj-modal-text">
                          At Merrill Lynch of Bank of America I developed
                          webpages using HTML5, vanilla CSS, vanilla-JS and
                          jQuery. When I am was coding + assisting the creation
                          of new landing pages, I was updating time-sensitive
                          content with the company's CMS, and keeping track of
                          multiple updates using Jira's Kanban board accross
                          several business owners, coworkers and other
                          stakeholders.
                        </div>
                      </div>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <a
                      href="https://www.merrilledge.com/investor-education"
                      target="_target"
                    >
                      <Button>
                        {" "}
                        <i class="fas fa-tv"></i> &nbsp;Visit Site
                      </Button>
                    </a>

                    <Button
                      style={{
                        background: "white",
                        color: "#040182",
                        border: "1px solid #040182",
                      }}
                      onClick={handleCloseBofa}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Col>

              {/* //////////////////////////////////// */}

              <Col>
                <Card className="highlight-on-hover" onClick={showMovies}>
                  <Card.Img
                    variant="top"
                    src="http://www.alfredorafael.com/wp-content/uploads/2024/02/CodeSample4.png"
                  />
                  <Card.Body>
                    <Card.Title>Movie App</Card.Title>
                  </Card.Body>
                </Card>

                <Modal
                  size="lg"
                  show={showMoviesState}
                  onHide={handleCloseMovies}
                >
                  {" "}
                  <Modal.Header className="proj-modal-header" closeButton>
                    <Modal.Title>Movie APP</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <div className="flexed-modal-body-proj">
                        <img
                          className="proj-modal-pic"
                          src="http://www.alfredorafael.com/wp-content/uploads/2024/02/CodeSample4.png"
                        />
                        <div className="proj-modal-text">
                          This is a fun-based project that fetches data from The
                          Movie Database API (TMDB) using React.js, allowing
                          users to see most-popular films, and search for
                          specific movies within TMDB's database. Users can
                          select movies and read additional information about
                          them: plot, actors, budget, etc. I built this
                          application with create-react-app, styled-components
                          for CSS styling, @reach/router for routing, prop-types
                          for components' output checking, and font-awesome
                          icons.
                        </div>
                      </div>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <a
                      href="https://alfredo-movie-app-with-react.netlify.app/"
                      target="_target"
                    >
                      <Button onClick={handleCloseMovies}>
                        {" "}
                        <i class="fas fa-tv"></i> &nbsp;Visit Site
                      </Button>
                    </a>

                    <a
                      href="https://github.com/alfredrafael/react-movie-app"
                      target="_blank"
                    >
                      <Button onClick={handleCloseMovies}>
                        {" "}
                        <i className="devicon-github-plain" /> &nbsp;See Code
                      </Button>
                    </a>
                    <Button
                      style={{
                        background: "white",
                        color: "#040182",
                        border: "1px solid #040182",
                      }}
                      onClick={handleCloseMovies}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Col>
            </Row>
          </Fade>

          {/* ////////////////////////////////////////////////////// */}

          <Fade>
            <Row className="project-rows">
              <Col>
                <Card className="highlight-on-hover" onClick={showMelodias}>
                  <Card.Img
                    variant="top"
                    src="http://www.alfredorafael.com/wp-content/uploads/2024/02/CodeSample5.png"
                  />
                  <Card.Body>
                    <Card.Title>Melodías Restaurant</Card.Title>
                  </Card.Body>
                </Card>

                <Modal
                  size="lg"
                  show={showMelodiasState}
                  onHide={handleCloseMelodias}
                >
                  <Modal.Header className="proj-modal-header" closeButton>
                    <Modal.Title>Melodias Restaurant</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <div className="flexed-modal-body-proj">
                        <img
                          className="proj-modal-pic"
                          src="http://www.alfredorafael.com/wp-content/uploads/2024/02/CodeSample5.png"
                        />
                        <div className="proj-modal-text">
                          I developed this restaurant's webpage + provided the
                          business owner with a QR code, so clients access their
                          Menu in a paperless fashion. Wordpress is a powerful
                          tool to develop enterprise level applications,
                          allowing clients edit their website's content thru a
                          wysiwyg editor. I created this UI theme with custom
                          HTML, vanilla CSS, and additional Worpdress pluggings
                          to enhance user experience.
                        </div>
                      </div>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <a href="https://melodias-restaurant.com" target="_target">
                      <Button>
                        {" "}
                        <i class="fas fa-tv"></i> &nbsp;Visit Site
                      </Button>
                    </a>

                    <Button
                      style={{
                        background: "white",
                        color: "#040182",
                        border: "1px solid #040182",
                      }}
                      onClick={handleCloseMelodias}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Col>

              {/* //////////////////////////////////// */}

              <Col>
                <Card className="highlight-on-hover" onClick={showRelata}>
                  <Card.Img
                    variant="top"
                    src="http://www.alfredorafael.com/wp-content/uploads/2024/02/CodeSample3.png"
                  />
                  <Card.Body>
                    <Card.Title>Relata US</Card.Title>
                  </Card.Body>
                </Card>

                <Modal
                  size="lg"
                  show={showRelataState}
                  onHide={handleCloseRelata}
                >
                  <Modal.Header className="proj-modal-header" closeButton>
                    <Modal.Title>Relata</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <div className="flexed-modal-body-proj">
                        <img
                          className="proj-modal-pic"
                          src="http://www.alfredorafael.com/wp-content/uploads/2024/02/CodeSample3.png"
                        />
                        <div className="proj-modal-text">
                          I developed this web-application with React.js,
                          React-Bootsrap and Email-js for its contact
                          funcionality. Relata is a digital media and marketing
                          agency aimed at building peace and understanding,
                          promoting dialogue, and interconnectedness for
                          nonprofits, NGOs, labor organizations, as well as
                          houses of worship, small businesses and entrepreneurs.
                        </div>
                      </div>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <a href="https://relata.us/" target="_target">
                      <Button onClick={handleCloseBofa}>
                        {" "}
                        <i class="fas fa-tv"></i> &nbsp;Visit Site
                      </Button>
                    </a>

                    <a
                      href="https://github.com/mylesgreen/relata"
                      target="_blank"
                    >
                      <Button onClick={handleCloseRelata}>
                        {" "}
                        <i className="devicon-github-plain" /> &nbsp;See Code
                      </Button>
                    </a>
                    <Button
                      style={{
                        background: "white",
                        color: "#040182",
                        border: "1px solid #040182",
                      }}
                      onClick={handleCloseRelata}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Col>

              {/* //////////////////////////////////// */}

              <Col>
                <Card className="highlight-on-hover" onClick={showASA}>
                  <Card.Img
                    variant="top"
                    src="http://www.alfredorafael.com/wp-content/uploads/2024/02/CodeSample6.png"
                  />
                  <Card.Body>
                    <Card.Title>American Standard</Card.Title>
                  </Card.Body>
                </Card>

                <Modal size="lg" show={showASAState} onHide={handleCloseASA}>
                  <Modal.Header className="proj-modal-header" closeButton>
                    <Modal.Title>American Standard Air</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <div className="flexed-modal-body-proj">
                        <img
                          className="proj-modal-pic"
                          src="http://www.alfredorafael.com/wp-content/uploads/2024/02/CodeSample6.png"
                        />
                        <div className="proj-modal-text">
                          As a software engineer at MTech, I work with a team of
                          desginers and fellow developers to code stateful and
                          stateless components using TypeScript and Next.js. We
                          use TailwindCSS in a headless CMS environment powered
                          by WordPress. In this role I also leverage tools such
                          as GIT, JIRA, Figma, and Asana in several team-based,
                          enterprise scale applications.
                        </div>
                      </div>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <a
                      href="https://www.americanstandardair.com/find-your-dealer/"
                      target="_target"
                    >
                      <Button>
                        {" "}
                        <i class="fas fa-tv"></i> &nbsp;Visit Site
                      </Button>
                    </a>

                    <Button
                      style={{
                        background: "white",
                        color: "#040182",
                        border: "1px solid #040182",
                      }}
                      onClick={handleCloseASA}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Col>
            </Row>
          </Fade>
          <br />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Projects;
