import React from "react";
import { Container } from "react-bootstrap";
import "./gilliard.css";
import Fade from "react-reveal/Fade";
import Division from "../../../Elements/Division";
import SubNav from "../../../Elements/SubNav";

const Gilliard = () => {
  return (
    <span id="gilliard">
      <SubNav
        title="Gilliard Research Group"
        // img="http://www.alfredorafael.com/wp-content/uploads/2019/02/whileLogoName-e1551079673184.png"
      />
      <Container style={{ padding: "2rem 0" }}>
        <Fade>
          <section
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 1em",
            }}
          >
            <div
              style={{
                borderRight: "1px solid darkgrey",
                display: "flex",
                flex: "1",
                flexDirection: "column",
                paddingRight: "1em",
              }}
            >
              <div>
                <strong>Client: </strong>
                ddd
              </div>
              <div>
                <strong>Client Contact: </strong>
                ddd
              </div>
              <div>
                <strong>Project Duration: </strong>
                ddd
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flex: "2",
                flexDirection: "column",
                marginLeft: "1em",
              }}
            >
              <div>
                <strong>Developer: </strong>
                ddd
              </div>
              <div>
                <strong>Developer Contact: </strong>
                ddd
              </div>
              <div>
                <strong>Fee </strong>
                ddd test
              </div>
            </div>
          </section>
        </Fade>
        <Division />
      </Container>
    </span>
  );
};

export default Gilliard;
