import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  Link as PDFLink,
} from "@react-pdf/renderer";
import TitlePDF from "../../Elements/TitlePDF";
import SavePDFButton from "../../Elements/SavePDFButton";

const content = [
  {
    type: "title",
    text: "Header goes here",
  },
  {
    type: "paragraph",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
  },
  {
    type: "subtitle",
    text: "SubTITLE goes here",
  },
  {
    type: "paragraph",
    text: "More text follows here...",
  },
  {
    type: "link",
    text: "Click here to visit Google",
    url: "https://www.google.com",
  },
];

const renderForPDF = (item) => {
  let textStyle = {};
  switch (item.type) {
    case "title":
    case "subtitle":
      textStyle = { margin: 10, fontSize: 14, fontWeight: "bold" };
      break;
    case "paragraph":
      textStyle = { margin: 10, fontSize: 12 };
      break;
    case "link":
      textStyle = { margin: 10, fontSize: 12 };
      break;
    default:
      return null;
  }
  return (
    <Text style={textStyle}>
      {item.type === "link" ? (
        <PDFLink src={item.url}>{item.text}</PDFLink>
      ) : (
        item.text
      )}
    </Text>
  );
};

const renderForWeb = (item) => {
  switch (item.type) {
    case "title":
    case "subtitle":
      // Assuming TitlePDF is a simple component, you might wrap it in a div with bold styling
      return (
        <div>
          <TitlePDF text={item.text} />
        </div>
      );
    case "paragraph":
      return <p>{item.text}</p>;
    case "link":
      return (
        <p>
          <a href={item.url} target="_blank" rel="noreferrer">
            {item.text}
          </a>
        </p>
      );
    default:
      return null;
  }
};

const PDFDocument = () => (
  <Document>
    <Page style={{ padding: 30 }}>{content.map(renderForPDF)}</Page>
  </Document>
);

const PDFContent = () => (
  <Container>
    {content.map((item, index) => (
      <Row key={index} className="mb-3">
        <Col>{renderForWeb(item)}</Col>
      </Row>
    ))}
  </Container>
);

const PDF = () => (
  <div id="shareData" style={{ minHeight: "75vh" }}>
    <PDFContent />
    <PDFDownloadLink document={<PDFDocument />} fileName="downloadablePDF.pdf">
      {({ loading }) =>
        loading ? (
          "Preparing document..."
        ) : (
          <Container>
            <SavePDFButton />
          </Container>
        )
      }
    </PDFDownloadLink>
  </div>
);

export default PDF;

// import React from "react";
// import { Container } from "react-bootstrap";
// import "./shareablePDF.css";
// import SubNav from "../../Elements/SubNav";
// import {
//   PDFDownloadLink,
//   Page,
//   Text,
//   View,
//   Document,
//   Link,
//   Image,
//   PDFViewer,
// } from "@react-pdf/renderer";
// import TitlePDF from "../../Elements/TitlePDF";
// import SavePDFButton from "../../Elements/SavePDFButton";

// const styles = {
//   page: {
//     fontSize: 10, // You can adjust the base font size here
//     fontFamily: "Helvetica",
//   },
//   text: {
//     margin: 5,
//     fontSize: 10, // Set smaller font size for text elements
//   },
//   link: {
//     fontSize: 10, // Ensure links have the same smaller font size
//   },
// };

// const PDF = () => {
//   const PDFContent = () => (
//     <Container>
//       <Document>
//         <Page style={styles.page}>
//           <View>
//             <TitlePDF text="Title goes here" />
//             <Text style={styles.text}>
//               Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
//               eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
//               enim ad minim veniam, quis nostrud exercitation ullamco laboris
//               nisi ut aliquip ex ea commodo consequat.
//             </Text>
//             <Text style={styles.text}>
//               Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
//               eiusmod tempor incididunt ut labore et dolore magna aliqua.
//               <Link
//                 src="https://www.google.com"
//                 target="_blank"
//                 style={styles.link}
//               >
//                 PDF link to google here
//               </Link>
//             </Text>
//             <TitlePDF text="Header 2 here" subtitle="Subtitle here" />
//             <Text style={styles.text}>More text follows here...</Text>
//           </View>
//         </Page>
//       </Document>
//     </Container>
//   );

//   return (
//     <div id="shareData">
//       <SubNav title="HTML to PDF Document" />
//       <Container>
//         <PDFContent />
//         <PDFDownloadLink document={<PDFContent />} fileName="savePDF.pdf">
//           {({ blob, url, loading, error }) =>
//             loading ? (
//               "Loading document..."
//             ) : (
//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "end",
//                 }}
//               >
//                 <SavePDFButton />
//               </div>
//             )
//           }
//         </PDFDownloadLink>
//       </Container>
//     </div>
//   );
// };

// export default PDF;

{
  /* https://blog.logrocket.com/generating-pdfs-react/ */
}

{
  /* <Image src="https://placekitten.com/g/600/500" style={styles.image} />
<img
src="https://placekitten.com/g/600/500"
alt="img"
style={{ display: "block", maxWidth: "100%" }}
/> */
}
