import React from "react";
import { Card } from "react-bootstrap";

const TheCard = ({ headerText, hoverEffect, children }) => {
  return (
    <>
      <Card className={hoverEffect && "hoverEffect"}>
        <Card.Header>{headerText}</Card.Header>
        <Card.Body>
          <Card.Text>{children}</Card.Text>
        </Card.Body>
      </Card>
      <style jsx>{`
        .card-header {
          color: white;
          // background: linear-gradient(
          //   to right,
          //   rgba(4, 1, 130, 1),
          //   rgba(4, 1, 130, 0.7),
          //   rgba(4, 1, 130, 0.5)
          // );
          background: rgba(4, 1, 130, 0.8);
        }

        .blueLink:hover {
          cursor: pointer;
        }
        .card-header {
          padding: 7px;
          text-align: center;
          font-weight: 500;
        }
        .hoverEffect {
          transition: transform 0.5s;
          -moz-transition: transform 0.5s;
          -ms-transition: transform 0.5s;
          -o-transition: transform 0.5s;
        }
        .hoverEffect:hover {
          box-shadow: 0 20px 70px 5px rgba(0, 0, 0, 0.15);
          transform: translateY(-7px);
          text-decoration: none;
          -webkit-transform: translateY(-7px);
          -moz-transform: translateY(-7px);
          -ms-transform: translateY(-7px);
          -o-transform: translateY(-7px);
        }
      `}</style>
    </>
  );
};

export default TheCard;
