import { Container, Col } from "react-bootstrap";

const ContactInfo = () => {
  return (
    <section
      className="contact-information"
      style={{
        // background: "rgba(173, 216, 230, .7)",
        background: "rgba(4, 1, 130, 0.9)",
        // background: "#040192",
        padding: "1% 0 .01% 1%",
        borderRadius: "5px",
      }}
    >
      <Container>
        <div style={{ display: "flex", marginBottom: ".5em" }}>
          <div style={{ minWidth: "52%" }}>
            <div style={{ display: "flex" }}>
              <a
                href="https://www.alfredorafael.com/wp-content/uploads/2024/10/Alfredo-R-Pabon-Resume.pdf"
                target="_blank"
                rel="noreferrer noopener"
              >
                <i
                  className="fas fa-file-pdf"
                  style={{
                    fontSize: "160%",
                    marginLeft: ".20%",
                    marginRight: "3.5%",
                    color: "white",
                  }}
                />{" "}
              </a>
              &nbsp; &nbsp;
              <a
                href="https://www.alfredorafael.com/wp-content/uploads/2024/10/Alfredo-R-Pabon-Resume.pdf"
                target="_blank"
                rel="noreferrer noopener"
              >
                <h2
                  className="text-in-contact-info"
                  style={{ fontSize: "110%", marginTop: "1%", color: "white" }}
                >
                  &nbsp; Download PDF &nbsp;
                </h2>
              </a>
            </div>
          </div>
          <div style={{ maxWidth: "52%" }}>
            <div style={{ display: "flex" }}>
              <a
                href="https://www.linkedin.com/in/alfredo-rafael/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i
                  className="fab fa-linkedin"
                  style={{
                    fontSize: "160%",
                    color: "white",
                    marginRight: "3%",
                  }}
                />{" "}
              </a>
              &nbsp; &nbsp;
              <a
                href="https://www.linkedin.com/in/alfredo-rafael/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <h2
                  style={{ fontSize: "110%", marginTop: "1%", color: "white" }}
                  className="text-in-contact-info"
                >
                  &nbsp; Connect via LinkedIn &nbsp;
                </h2>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ContactInfo;

//   <div style={{ display: "flex", marginBottom: "1.5%" }}>
//          <div style={{ minWidth: "52%" }}>
//           <div style={{ display: "flex" }}>
//             <a
//               href="tel:+16175801400"
//               rel="noopener noreferrer"
//               target="_blank"
//             >
//               <i
//                 className="fas fa-phone-square-alt"
//                 style={{
//                   fontSize: "160%",
//                   marginLeft: ".20%",
//                   marginRight: "3.5%",
//                   color: "#464646",
//                 }}
//               />{" "}
//             </a>
//             &nbsp;
//             <a
//               href="tel:+16175801400"
//               rel="noopener noreferrer"
//               target="_blank"
//             >
//               <h2
//                 className="text-in-contact-info"
//                 style={{ fontSize: "110%", marginTop: "1%" }}
//               >
//                 {" "}
//                 &nbsp; (617) 580-1400 &nbsp;
//               </h2>
//             </a>
//           </div>
//         </div>

//  <div style={{ maxWidth: "52%" }}>
//           <div style={{ display: "flex" }}>
//             <a
//               href="https://www.linkedin.com/in/alfredo-rafael/"
//               rel="noopener noreferrer"
//               target="_blank"
//             >
//               <i
//                 className="fab fa-linkedin"
//                 style={{
//                   fontSize: "160%",
//                   color: "#464646",
//                   marginRight: "3%",
//                 }}
//               />{" "}
//             </a>
//             &nbsp; &nbsp;
//             <a
//               href="https://www.linkedin.com/in/alfredo-rafael/"
//               rel="noopener noreferrer"
//               target="_blank"
//             >
//               <h2
//                 style={{ fontSize: "110%", marginTop: "1%" }}
//                 className="text-in-contact-info"
//               >
//                 &nbsp; linkedin/alfredo-rafael &nbsp;
//               </h2>
//             </a>
//           </div>
//         </div>
//       </div>*/

//     <div style={{ maxWidth: "52%" }}>
//           <div style={{ display: "flex" }}>
//             <a
//               href="mailto:alfredrafael@gmail.com?Subject=Regarding%20your%20web-development%20services"
//               rel="noopener noreferrer"
//               target="_blank"
//             >
//               <i
//                 className="fas fa-envelope"
//                 style={{
//                   fontSize: "150%",
//                   color: "#464646",
//                   marginRight: "3%",
//                 }}
//               />{" "}
//             </a>
//             &nbsp; &nbsp;
//             <a
//               href="mailto:alfredrafael@gmail.com?Subject=Regarding%20your%20web-development%20services"
//               rel="noopener noreferrer"
//               target="_blank"
//             >
//               <h2
//                 style={{ fontSize: "110%", marginTop: "1%" }}
//                 className="text-in-contact-info"
//               >
//                 &nbsp;alfredrafael@gmail.com &nbsp;
//               </h2>
//             </a>
//           </div>
//         </div>
