import React from "react";
import { Row, Col } from "react-bootstrap";
import TheLink from "./TheLink";

const SideBySideResponse = ({ titleLeft, titleRight, statement, response }) => {
  return (
    <>
      {/* Text with Reply ////////////////////////////////////////////////////////// */}
      <Row style={{ justifyContent: "center" }}>
        <Col style={{ fontSize: "90%" }}>
          {titleLeft && (
            <div>
              <h4>{titleLeft}</h4>
            </div>
          )}
          <i>{statement}</i>
        </Col>
        <Col style={{ fontSize: "90%" }}>
          {titleRight && (
            <div>
              <h4>{titleRight}</h4>
            </div>
          )}
          {response}
        </Col>
      </Row>
      <hr />
    </>
  );
};

export default SideBySideResponse;
