import React from "react";

const PicWithText = ({ side, picUrl, caption, text }) => {
  const imageSideClass = side === "right" ? "image-right" : "image-left";
  return (
    <div className={`picture-with-text ${imageSideClass}`}>
      <div className="picture">
        <img src={picUrl} alt={caption} />
        <div className="caption">{caption}</div>
      </div>
      <div className="text-content">{text}</div>
      <style jsx>{`
        .picture-with-text {
          display: flex;
          align-items: flex-start;
          margin-bottom: 20px;
          max-width: 100%; /* Ensures the container never exceeds the viewport width */
          overflow-x: hidden; /* Prevents horizontal overflow */
        }

        .image-left {
          flex-direction: row;
        }

        .image-right {
          flex-direction: row-reverse;
        }

        .picture {
          max-width: 50%;
          margin-right: 20px;
          object-fit: cover;
          flex-shrink: 0; /* Prevents image from shrinking smaller than its content */
          margin-top: 1em;
          margin-bottom: 1em;
          border-radius: 5px;
          border: 1px solid #d3d3d3;
          padding: 0.5em;
          /*background-color: rgba(4, 1, 130, 0.1);*/
          /*background: linear-gradient(to bottom, #7673bf71 70%, white 100%);*/
          background: #7673bf53;
        }
        .picture img {
          width: 100%;
          height: auto;
          border-radius: 5px;
        }

        .image-right .picture {
          margin-left: 20px;
          margin-right: 0;
        }

        .text-content {
          flex: 1;
          min-width: 0; /* Prevents text from overflowing the container */
        }

        .caption {
          margin: 1em 0 1em 0;
          font-size: 0.85rem;
          text-align: center;
          font-weight: 500;
        }

        /* Media query for smaller screens */
        @media (max-width: 768px) {
          .picture-with-text {
            flex-direction: column;
            align-items: center;
            text-align: center;
          }

          .picture {
            margin: 0 0 20px 0;
            max-width: 100%;
            margin: 1em 0;
          }

          .image-right .picture {
            margin-left: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default PicWithText;
