import { Button } from "react-bootstrap";

const SavePDFButton = () => {
  return (
    <div>
      <Button style={{ backgroundColor: "#040182", fontWeight: "700" }}>
        Save as PDF
      </Button>
    </div>
  );
};

export default SavePDFButton;
