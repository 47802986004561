import React from "react";
import HeroBanner from "../../Elements/2HeroBanner";
import About from "../../Elements/4About";
import Services from "../../Elements/5DServices";
import Projects from "../../Elements/6Projects";
import "./home.css";

const Home = () => {
  return (
    <React.Fragment>
      <HeroBanner />
      <About />
      <Services />
      <Projects />
    </React.Fragment>
  );
};

export default Home;
