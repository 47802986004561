import React from "react";
import "./dServices.css";
import { Row, Col, Container } from "react-bootstrap";
import Fade from "react-reveal/Fade";

const DServices = () => {
  return (
    <React.Fragment>
      <section id="services" style={{ justifyContent: "space-around" }}>
        <Container>
          <h2>
            <Fade>
              <strong>
                How Can <span>I Help You?</span>
              </strong>
            </Fade>
          </h2>

          <Row className="projectRow">
            <Col className="serviceImageDiv" style={{ textAlign: "center" }}>
              <h4 className="mobileServiceText">UX Design</h4>
              <img
                src="http://www.alfredorafael.com/wp-content/uploads/2024/02/UX_Diagram.png"
                alt="UX design example"
                style={{
                  marginLeft: "-2em",
                }}
              />
            </Col>
            <Col className="serviceTextDiv">
              <h4 className="desktopServiceText">UX Design </h4>
              <p>
                Need to translate your idea into a fully functional
                web-application? From logo design and color pallete to
                wireframe, we'll develop a template that mirrors your
                application's look and techincal needs before diving into
                production.
              </p>
            </Col>
          </Row>

          <hr
            style={{
              border: ".5px solid #D3D3D3",
              maxWidth: "25%",
              marginBottom: "3em",
            }}
          />
          <Fade>
            <Row className="projectRow" style={{}}>
              <Col className="serviceImageDiv" style={{ textAlign: "center" }}>
                <h4 className="mobileServiceText">UI Development</h4>

                <img
                  style={{ maxWidth: "52%" }}
                  src="http://www.alfredorafael.com/wp-content/uploads/2024/02/Dev_Diagram.png"
                  alt="UI development logo"
                />
              </Col>
              <Col
                className="serviceTextDiv"
                style={{
                  margin: "auto 0",
                }}
              >
                <h4 className="desktopServiceText">UI Development</h4>
                <p>
                  Once we identify your website's design, feel and technical
                  needs, we'll use our software development tools to bring your
                  project to life. My computer languages and areas of expertise
                  are Javascript (jQuery and React.js) CSS, HTML5, Github,
                  Netlify and Wordpress CMS.
                </p>
              </Col>
            </Row>
          </Fade>

          <hr
            style={{
              border: ".5px solid #D3D3D3",
              maxWidth: "25%",
              marginBottom: "3em",
            }}
          />
          <Fade>
            <Row className="projectRow" style={{}}>
              <Col className="serviceImageDiv" style={{ textAlign: "center" }}>
                <h4 className="mobileServiceText">Tech Advisory</h4>

                <img
                  src="http://www.alfredorafael.com/wp-content/uploads/2024/02/Advisor_Diagram.png"
                  alt="tech advisory logo"
                  style={{ marginRight: "-1em" }}
                />
              </Col>
              <Col
                className="serviceTextDiv"
                style={{
                  margin: "auto 0",
                }}
              >
                <h4 className="desktopServiceText">Tech Advisory </h4>
                <p>
                  Along the way, I'll provide you with advice on best
                  user-expereince practices, software alternatives and
                  maintenace tools that best suit your project needs. Developing
                  this helpful relationship with clients is one of the
                  dimensions I enjoy the most.
                </p>
              </Col>
            </Row>
          </Fade>

          <br />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default DServices;
