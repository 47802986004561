import React from "react";

const ThumbnailOnSide = ({ imgSrc, alt, children, imgText, right, left }) => {
  return (
    <section>
      <div
        className="thumbnail-and-text"
        style={{
          maxWidth: "100%",
          margin: left ? "1em 2em .5em 0" : "1em 0 .5em 2em",
          float: right ? "right" : "left",
          width: "30%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          padding: ".5em",
          borderRadius: "5px",
          border: "1px solid rgba(4, 1, 130, .2)",
        }}
      >
        <img style={{ borderRadius: "5px" }} src={imgSrc} alt={alt} />

        {imgText && (
          <span
            className="pic-text"
            style={{ fontSize: "small", padding: ".5em 0" }}
          >
            {imgText}
          </span>
        )}
      </div>
      {children}
    </section>
  );
};
export default ThumbnailOnSide;
