const Division = () => {
  return (
    <div>
      <div style={{ height: "1rem" }}></div>
      <hr style={{ border: ".5px solid #D3D3D3", maxWidth: "40%" }} />
      <div style={{ height: "1rem" }}></div>
    </div>
  );
};

export default Division;
