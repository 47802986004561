import React from "react";
import { Container, Navbar } from "react-bootstrap";

const SubNav = ({ title, img }) => {
  return (
    <Navbar
      style={{
        background: "#7573bf",
        padding: "1em",
        marginBottom: "2em",
      }}
    >
      <Container
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        {img && (
          <Navbar.Brand>
            <img alt="logo" src={img} style={{ maxWidth: "3em", margin: "" }} />
          </Navbar.Brand>
        )}
        <strong style={{ color: "white" }}>
          {img && <span style={{ marginRight: ".5em" }}></span>}
          {title}
        </strong>
      </Container>
    </Navbar>
  );
};

export default SubNav;
